label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}


.navbar {
  background-color: #ff8000;
  color: #000080;
}

.handle-new-line {
  white-space: pre-wrap;
}

.light-grey {
  background-color: lightgrey;
}

.normal-grey {
  background-color: grey;
}

.iframe {
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
}

.full-height {
  overflow: hidden;
  height: 100%;
  position: absolute;
}

.btn-xls {
  margin-bottom: 10px;
}